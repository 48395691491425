<script>
/***
 Name: create
 Code: sa0ChunLuyu
 Time: 2021/11/15 19:28
 Remark: 创建
 */
export default {
  props: ['reloadList'],
  data() {
    return {
      name: '',
      remark: '',
      yp_arr: [],
      create_data: {
        "id": "0",
        "spec_type": "0",
        "name": "",
        "in_number": "",
        "out_number": "",
        "check_item": "0",
        "check_func": "0",
        "check_per": "0",
        "get_per": "0",
        "ck_per": "0"
      }
    }
  },
  watch: {
    $route: {
      handler() {
        this.getItemInfo()
      },
      deep: true
    },
  },
  mounted() {
    this.getItemInfo()
  },
  methods: {
    getItemInfo() {
      this.$sa0.post({
        url: this.$api('样品组信息'),
        data: {
          id: this.$route.params.id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.name = response.data.info.name;
            this.remark = response.data.info.remark;
            this.yp_arr = response.data.list;
            this.create_data = {
              "id": "0",
              "spec_type": "0",
              "name": "",
              "in_number": "",
              "out_number": "",
              "check_item": "0",
              "check_func": "0",
              "check_per": "0",
              "get_per": "0",
              "ck_per": "0"
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    createGroup() {
      if (this.yp_arr.length === 0) return layer.msg('请添加样品')
      this.$sa0.post({
        url: this.$api('修改样品组'),
        data: {
          id: this.$route.params.id,
          name: this.name,
          remark: this.remark,
          yp_arr: this.yp_arr
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('修改成功')
            this.reloadList()
            this.getItemInfo()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    delThis(key) {
      this.yp_arr.splice(key, 1)
    },
    createYp() {
      if (this.create_data.in_number === '') return layer.msg('请输入内部编号');
      this.yp_arr.push(this.create_data)
      this.create_data = {
        "id": "0",
        "spec_type": "0",
        "name": "",
        "in_number": "",
        "out_number": "",
        "check_item": "0",
        "check_func": "0",
        "check_per": "0",
        "get_per": "0",
        "ck_per": "0"
      }
    },
    getTypeList() {
    },
    getItemList() {
    },
    getFuncList() {
    },
  }
}
</script>
<template>
  <div>
    <div class="title_wrapper">修改样品组</div>
    <div class="table_wrapper">
      <table class="layui-table">
        <colgroup>
          <col width="150">
          <col width="200">
          <col>
        </colgroup>
        <tbody>
        <tr>
          <td>名称</td>
          <td colspan="9">
            <input type="text" v-model="name" class="input_wrapper">
          </td>
        </tr>
        <tr>
          <td>样品类型</td>
          <td>样品名称</td>
          <td>内部编号</td>
          <td>外部编号</td>
          <td>检查项目</td>
          <td>检查方法</td>
          <td>采集人</td>
          <td>接收人</td>
          <td>检测人</td>
          <td>操作</td>
        </tr>
        <tr v-for="(item,key) in yp_arr">
          <td>样品类型</td>
          <td><input type="text" class="input_wrapper" v-model="item.name"></td>
          <td><input type="text" class="input_wrapper" v-model="item.in_number"></td>
          <td>外部编号</td>
          <td>检查项目</td>
          <td>检查方法</td>
          <td>采集人</td>
          <td>接收人</td>
          <td>检测人</td>
          <td>
            <button @click="delThis(key)" class="layui-btn layui-btn-danger layui-btn-sm">删除</button>
          </td>
        </tr>
        <tr>
          <td>样品类型</td>
          <td><input type="text" class="input_wrapper" v-model="create_data.name"></td>
          <td><input type="text" class="input_wrapper" v-model="create_data.in_number"></td>
          <td>外部编号</td>
          <td>检查项目</td>
          <td>检查方法</td>
          <td>采集人</td>
          <td>接收人</td>
          <td>检测人</td>
          <td>
            <button @click="createYp()" class="layui-btn layui-btn-normal layui-btn-sm">添加</button>
          </td>
        </tr>
        <tr>
          <td>备注</td>
          <td colspan="9">
            <input type="text" v-model="remark" class="input_wrapper">
          </td>
        </tr>
        </tbody>
      </table>
      <div>
        <button @click="createGroup()" class="layui-btn layui-btn-normal">保存</button>
      </div>
    </div>
  </div>
</template>
<style scoped>
td {
  line-height: 40px;
  padding: 0 10px;
}

.input_wrapper {
  width: 100%;
  height: 100%;
}

.title_wrapper {
  font-size: 26px;
  font-weight: bold;
}
</style>
