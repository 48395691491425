<script>
    /***
     Name: create
     Code: sa0ChunLuyu
     Time: 2021/11/15 19:28
     Remark: 创建
     */
    export default {
        props: ['reloadList'],
        data() {
            return {
                name: '',
                remark: '',
                yp_arr: [],
                yptype_options: [],
                checkitem_options: [],
                checkfunc_options: [],
                caiji_options: [],
                jiehsou_options: [],

                yptype_options1: [],
                checkitem_options1: [],
                checkfunc_options1: [],
                caiji_options1: [],
                jiehsou_options1: [],
                create_data: {
                    "id": "0",
                    "spec_type": [],
                    "name": "",
                    "in_number": "",
                    "out_number": "",
                    "check_item": [],
                    "check_func": [],
                    "check_per": [],
                    "get_per": [],
                    "ck_per": '',
                },

            }
        },
        mounted() {
            this.getTypeList()
            this.getItemList()
            this.getFuncList()
            this.caijilist()
            this.jieshoulist()
        },
        methods: {
            createGroup() {
                if (this.yp_arr.length === 0) return layer.msg('请添加样品')
                this.$sa0.post({
                    url: this.$api('样品组创建'),
                    data: {
                        name: this.name,
                        remark: this.remark,
                        yp_arr: this.yp_arr
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('创建成功')
                            this.$router.push('/sampleguanli/group/' + response.data.id)
                            this.reloadList()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            delThis(key) {
                this.yp_arr.splice(key, 1)
            },
            createYp() {
                if (this.create_data.in_number === '') return layer.msg('请输入内部编号');
                this.yp_arr.push(this.create_data)
                this.create_data = {
                    "id": "0",
                    "spec_type": [],
                    "name": "",
                    "in_number": "",
                    "out_number": "",
                    "check_item": [],
                    "check_func": [],
                    "check_per": [],
                    "get_per": [],
                    "ck_per": '',
                }
            },

            // 样品类型
            getTypeList() {
                this.$sa0.post({
                    url: this.$api('Samplecategoryalllist'),
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.yptype_options = response.data.list;
                            this.yptype_options1 = response.data.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 检测项目
            getItemList() {
                this.$sa0.post({
                    url: this.$api('获取检测项目所有分级列表'),
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.checkitem_options = response.data.list;
                            this.checkitem_options1 = response.data.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 检测方法
            getFuncList() {
                this.$sa0.post({
                    url: this.$api('检测方法所有列表'),
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.checkfunc_options = response.data.list;
                            this.checkfunc_options1 = response.data.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 采集人列表
            caijilist() {
                this.$sa0.post({
                    url: this.$api('Dandaalllist'),
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.caiji_options = response.data.list;
                            this.caiji_options1 = response.data.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 采集人列表
            jieshoulist() {
                this.$sa0.post({
                    url: this.$api('Dandaalllist'),
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.jiehsou_options = response.data.list;
                            this.jiehsou_options1 = response.data.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
        }
    }
</script>
<template>
    <div>
        <div class="title_wrapper">创建样品组</div>
        <div class="table_wrapper">
            <table class="layui-table" style="width: 1500px">
                <thead>
                <tr>
                    <th>名称</th>
                    <th colspan="9">
                        <a-input v-model="name" placeholder="" class="w200"/>
                    </th>
                </tr>
                <tr>
                    <th>样品类型</th>
                    <th>样品名称</th>
                    <th>内部编号</th>
                    <th>外部编号</th>
                    <th>检查项目</th>
                    <th>检查方法</th>
                    <th>采集人</th>
                    <th>接收人</th>
                    <th>检测人</th>
                    <th>操作</th>
                </tr>
                </thead>
                <colgroup>
                    <col width="150">
                    <col width="200">
                    <col>
                </colgroup>
                <tbody>
                <tr v-for="(item,key) in yp_arr">
                    <td>
                        <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" :options="yptype_options1" placeholder="请选择" class="w200" v-model="item.spec_type"/>
                    </td>

                    <td>
                        <a-input v-model="item.name" placeholder="" class="w100"/>
                    </td>
                    <td>
                        <a-input v-model="item.in_number" placeholder="" class="w100"/>
                    </td>
                    <td>
                        <a-input v-model="item.out_number" placeholder="" class="w100"/>
                    </td>
                    <td>
                        <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" :options="checkitem_options1" placeholder="请选择" class="w200" v-model="item.check_item"/>
                    </td>
                    <td>
                        <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" :options="checkfunc_options1" placeholder="请选择" class="w200" v-model="item.check_func"/>
                    </td>
                    <td>
                        <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" :options="caiji_options1" placeholder="请选择" class="w200" v-model="item.check_per"/>
                    </td>
                    <td>
                        <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" :options="jiehsou_options1" placeholder="请选择" class="w200" v-model="item.get_per"/>
                    </td>
                    <td>
                        <a-input v-model="item.ck_per" placeholder="" class="w200"/>
                    </td>
                    <td>
                        <a-button type="danger" @click="delThis(key)">删除</a-button>

                    </td>
                </tr>
                <tr>
                    <td>
                        <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" :options="yptype_options" placeholder="请选择" class="w200" v-model="create_data.spec_type"/>
                    </td>
                    <td>
                        <a-input v-model="create_data.name" class="w100"/>
                    </td>
                    <td>
                        <a-input type="text" class="input_wrapper w100" v-model="create_data.in_number" />
                    </td>
                    <td>
                        <a-input type="text" class="input_wrapper w100" v-model="create_data.out_number"/>
                    </td>
                    <td>
                        <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" :options="checkitem_options" placeholder="请选择" class="w200" v-model="create_data.check_item"/>
                    </td>
                    <td>
                        <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" :options="checkfunc_options" placeholder="请选择" class="w200" v-model="create_data.check_func"/>
                    </td>
                    <td>
                        <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" :options="caiji_options" placeholder="请选择" class="w200" v-model="create_data.check_per"/>
                    </td>
                    <td>
                        <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" :options="jiehsou_options" placeholder="请选择" class="w200" v-model="create_data.get_per"/>
                    </td>
                    <td>
                        <a-input type="text" class="input_wrapper w200" v-model="create_data.ck_per" />
                    </td>
                    <td>
                        <a-button type="primary" @click="createYp()">
                            添加
                        </a-button>
                    </td>
                </tr>
                <tr>
                    <td>备注</td>
                    <td colspan="9">
                        <a-input type="text" class="input_wrapper" v-model="remark" />
                    </td>
                </tr>
                </tbody>
            </table>
            <div>
                <button @click="createGroup()" class="layui-btn layui-btn-normal">保存</button>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .title_wrapper{
        font-size: 26px;
        font-weight: bold;
        }
    .w200{ width: 180px}
    .w100{ width: 100px}

</style>

